import React from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletAddress from './reusable/WalletAddress';

const Wallet = ({ blockchainData }) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center" // Center everything horizontally
        alignItems="center"
        flexDirection="column" // Stack elements vertically on small screens
        sx={{
          width: '100%',
          padding: 1,
        }}
      >
        {!blockchainData.walletAddress ? (
          <Button variant="contained" color="primary" onClick={blockchainData.connectWallet} sx={{ width: '100%' }}>
            Connect Wallet
          </Button>
        ) : (
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }} // Column on xs, row on sm and up
            justifyContent="center" // Center children horizontally
            alignItems="center" // Center children vertically
            width="100%"
            gap={{ xs: 1, sm: 2 }} // Adjust gap between items
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center" // Center the wallet address
              sx={{
                border: "1px solid #1976d2",
                borderRadius: "5px",
                padding: "6px",
                display: "flex",
              }}
            >
              <IconButton color="primary">
                <AccountBalanceWalletIcon />
              </IconButton>
              <WalletAddress length={12} walletAddress={blockchainData.walletAddress || "0x000000000"} />
            </Box>

            <Box
              display="flex"
              justifyContent="center" // Center the ETH and Token balance fields together
              alignItems="center"
              width="100%"
              sx={{
                flexDirection: { xs: 'row', sm: 'row' }, // Keep inline for all sizes
              }}
            >
              {blockchainData.ethBalance && (
                <TextField
                  label="BNB Balance"
                  value={Number(blockchainData.ethBalance).toFixed(6)}
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  size="small"
                  sx={{ width: { xs: '45%', sm: 'auto' }, maxWidth: '200px' }} // Adjust width for mobile, set max width to prevent overflow
                />
              )}
              {blockchainData.userTokenBalance && (
                <TextField
                  label="Token Balance"
                  value={blockchainData.userTokenBalance}
                  variant="outlined"
                  InputProps={{ readOnly: true }}
                  size="small"
                  sx={{ width: { xs: '45%', sm: 'auto' }, maxWidth: '200px' }} // Adjust width for mobile, set max width to prevent overflow
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Wallet;
