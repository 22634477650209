import React, { useState } from 'react';
import {
  Box,
  Typography,
  Pagination,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const ProposalControls = ({
  proposalCount,
  currentPage,
  totalPages,
  onPaginationChange,
  sortOption,
  onSortChange,
  filterOptions,
  onSearchProposalById
}) => {
  const [searchId, setSearchId] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearchChange = (e) => {
    setSearchId(e.target.value);
  };

  const handleSearch = () => {
    onSearchProposalById(searchId);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <Box>
        <Typography color="textPrimary" variant="h6" sx={{ fontSize: { xs: '0.875rem', sm: '1.25rem', marginTop: "30px" } }}>
          {proposalCount} Proposals
        </Typography>
        <Pagination style={{marginBottom: "20px"}} count={totalPages} page={currentPage} onChange={onPaginationChange} color="primary" />
      </Box>

      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto', // Enable horizontal scrolling
          width: '100%', // Ensure the box tries to fill its parent
          // This min-width property ensures that the Box has a reason to scroll.
          // Adjust this value based on your content's needs or remove if unnecessary
          minWidth: '300px',
          marginBottom: "30px",
          paddingBottom: "10px"
        }}
      >

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', flexWrap: 'nowrap' }}>
          <Select value={sortOption} onChange={onSortChange} size={isSmallScreen ? "small" : "medium"}>
            <MenuItem value="latest">Latest</MenuItem>
            <MenuItem value="oldest">Oldest</MenuItem>
            <MenuItem value="mostVoted">Most Voted</MenuItem>
          </Select>

          {filterOptions.map(({ filterType, filterValue, onChange, menuItems }) => (
            <Select key={filterType} value={filterValue} onChange={onChange} displayEmpty size={isSmallScreen ? "small" : "medium"}>
              {menuItems.map(({ value, label }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          ))}

          <TextField
            label="Search ID"
            variant="outlined"
            size="small"
            value={searchId}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: "150px", fontSize: { xs: '0.75rem', sm: '1rem' } }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProposalControls;
