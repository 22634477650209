import Web3 from 'web3';

const readOnlyRpcUrl = 'https://data-seed-prebsc-1-s1.binance.org:8545'; // RPC URL

export const getWeb3 = (type = 'readOnly') => {
  if (type === 'transactional' && Web3.givenProvider) {
    // For transactional operations, use the user's wallet provider
    return new Web3(Web3.givenProvider);
  } else {
    // For read-only operations, use the RPC URL
    return new Web3(readOnlyRpcUrl);
  }
};

export const getBalance = async (address) => {
  const web3 = getWeb3();
  const balance = await web3.eth.getBalance(address);
  return web3.utils.fromWei(balance, 'ether');
};
