import React, { createContext, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton as MuiIconButton, styled } from '@mui/material';

export const HelpContext = createContext();

export const HelpProvider = ({ children, helpContent }) => {
  const [helpData, setHelpData] = useState({});
  const [open, setOpen] = useState(false);

  const showHelp = (helpId) => {
    const content = helpContent.find(item => item.id === helpId) || {};
    setHelpData(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setHelpData({});
  };

  const CustomHelpIcon = styled(HelpOutlineIcon)({
    fontSize: '19px', // Keep your custom styling
  });

  const HelpIcon = ({ helpId }) => (
    <MuiIconButton onClick={() => showHelp(helpId)} size="small">
      <CustomHelpIcon style={{ color: "#ffffffb8" }} />
    </MuiIconButton>
  );

  const HelpDialog = () => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        BackdropProps: {
          invisible: true, // This makes the backdrop invisible
        },
      }}
    >
      <Box
        sx={{ width: 250, padding: 2 }}
        role="presentation"
      >
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>
          {helpData.title}
        </Typography>
        <Typography variant="body1">
          {helpData.description}
        </Typography>
        {/* Add more content or components as needed */}
      </Box>
    </Drawer>
  );

  return (
    <HelpContext.Provider value={{ HelpIcon }}>
      {children}
      <HelpDialog />
    </HelpContext.Provider>
  );
};
