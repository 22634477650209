import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, Box, Typography, ToggleButtonGroup, ToggleButton, useMediaQuery, ButtonGroup, Button, AppBar, Toolbar } from '@mui/material';
import Wallet from './components/Wallet';
import GovernanceInfo from './components/GovernanceInfo';
import ConsensusInfo from './components/ConsensusInfo';
import GamesInfo from './components/GamesInfo';
import ConsensusInternalInfo from './components/ConsensusInternalInfo';
import GovernanceApprovalInfos from './components/GovernanceApprovalInfos';
import CreateConsensusProposal from './components/CreateConsensusProposal';
import CreateProposal from './components/CreateProposal';
import MaterialUISwitch from './components/MaterialUISwitch'; // Import your switch component
import TokenInfo from './components/TokenInfo';
import { useBlockchainData } from './hooks/useBlockchainData';
import { NotificationProvider } from './NotificationContext';
import { SnackbarProvider } from 'notistack';
import { HelpProvider } from './HelpContext';
import helpContent from './helpContent.json'; // Adjust the path as necessary
import { ConfirmationProvider } from './ConfirmationContext'; // Import the context provider
import logo from './logo.png'; // Importing the image
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { tokenContractAddress } from './utils/contractConfig';
import WalletAddress from './components/reusable/WalletAddress';
import { CircularProgress } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CloudOffIcon from '@mui/icons-material/CloudOff';

// Define light and dark themes
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    // ... other light theme settings
  },
  // ... other theme settings
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212', // Primary dark color
      paper: '#1e1e1e',  // Secondary lighter dark color
    },
  },
  // ... other theme settings
});

function App() {
  const blockchainData = useBlockchainData();
  const [currentView, setCurrentView] = useState('Governance');
  const [consensusView, setConsensusView] = useState('ApprovalReview');
  const [theme, setTheme] = useState(lightTheme); // Default to light theme

  const matches = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.backgroundColor = theme.palette.mode === 'dark' ? darkTheme.palette.background.paper : lightTheme.palette.background.default;
      root.style.minHeight = '100vh'; // Ensure full screen height
    }
    // Ensure the entire HTML and body elements are also set to full height
    document.documentElement.style.height = '100%';
    document.body.style.height = '100%';
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme.palette.mode === 'light' ? darkTheme : lightTheme);
  };

  const handleToggle = (event, newView) => {
    if (newView !== null) {
      setCurrentView(newView);
    }
  };

  const handleConsensusViewChange = (newView) => {
    if (newView !== null) {
      setConsensusView(newView);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4}>
        <NotificationProvider>
          <HelpProvider helpContent={helpContent}>
            <ConfirmationProvider>

              <AppBar position="static" color="primary" sx={{ backgroundColor: '#1976d2' }}>
                <Toolbar>
                  <CurrencyExchangeIcon />
                  <Box flexGrow={1} display="flex" justifyContent="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography variant="body2" color="inherit" sx={{ fontWeight: "bolder" }} noWrap>
                        GET WBLOCK ON <a style={{color:"white"}} href="https://pancakeswap.finance/swap?chain=bscTestnet&outputCurrency=0x9E44873649A8A45589932A220f8e7861F45f78C7" target="_blank" rel="noopener noreferrer">PANCAKESWAP</a>
                      </Typography>
                      <WalletAddress noWrap length={10} walletAddress={tokenContractAddress} color="white" />
                    </Box>
                  </Box>
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  m: 'auto',
                  p: { xs: 2, sm: 3 },
                  maxWidth: '1200px',
                  backgroundColor: theme.palette.background.default,
                  boxShadow: theme.palette.mode === 'dark' ? "0 1px 10px #000" : "0 1px 10px #f9f0f0a3",
                  borderRadius: "12px",
                  minHeight: '100vh',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      maxWidth: "1170px",
                      flexDirection: matches ? 'column' : 'row', // Adjust based on screen size
                      alignItems: matches ? 'start' : 'center',
                    }}
                  >
                    <Box display="flex" alignItems="center" sx={{ mb: matches ? 2 : 0 }}>
                      <img src={logo} alt="logo" style={{ width: "80px", marginRight: "10px" }} />
                      <Typography variant="h5" color="textPrimary">
                        <b>ECOSYSTEM</b><br />Holder's App
                      </Typography>

                      <div style={{ position: "absolute", top: "2px", right: "2px" }} >
                        <MaterialUISwitch checked={theme.palette.mode === 'dark'} onChange={toggleTheme} />

                      </div>

                    </Box>
                    <Box display="flex" justifyContent="flex-end" sx={{ width: matches ? '100%' : 'auto', alignItems: "center" }}>
                      <Wallet blockchainData={blockchainData} />
                    </Box>
                  </Box>


                  {blockchainData.walletAddress ? (
                    <>
                      {blockchainData.settings.length > 5 ? (
                        <Box marginTop="20px">
                          <TokenInfo blockchainData={blockchainData} />
                        </Box>
                      ) : (
                        <><Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                          <CircularProgress />
                        </Box></>
                      )}
                    </>
                  ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
                      <Typography position="absolute" sx={{ fontSize: 40, color: "#8080800f", top: "40%", textAlign: "center" }}>NOT CONNECTED</Typography>
                      <CloudOffIcon sx={{ fontSize: 300, color: "#8080800f" }} />
                    </Box>
                  )}

                </Box>

                {blockchainData.walletAddress ? (
                  // If the wallet is connected, render all the app's content

                  <>
                    <ToggleButtonGroup
                      value={currentView}
                      exclusive
                      onChange={handleToggle}
                      aria-label="View Selector"
                      size="large"
                      color="primary"
                      sx={{ textAlign: "left", margin: "2%" }}
                    >
                      <ToggleButton
                        value="Governance"
                        style={{ color: theme.palette.text.primary }} // Updated
                      >Governance</ToggleButton>
                      <ToggleButton
                        value="Consensus"
                        style={{ color: theme.palette.text.primary }} // Updated
                      >Consensus</ToggleButton>
                    </ToggleButtonGroup>

                    {/* Content for toggle */}
                    <Box sx={{ width: '96%' }}> {/* Adjust width here */}

                      {currentView === 'Governance' &&
                        <Box sx={{ display: { xs: 'contents', sm: 'flex' }, width: '100%', height: '250px' }}>
                          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 1 }}>
                            <GamesInfo blockchainData={blockchainData} />
                          </Box>
                          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 1 }}>
                            <CreateProposal
                              account={blockchainData.walletAddress}
                              proposalCostPercent={blockchainData.settings && blockchainData.settings[5] ? blockchainData.settings[5].value : 0}
                              tokenDecimals={blockchainData.decimals}
                              totalSupply={blockchainData.totalSupply}
                            />
                          </Box>
                        </Box>
                      }
                      {currentView === 'Governance' && <GovernanceInfo blockchainData={blockchainData} />}

                      {currentView === 'Consensus' && (
                        <>
                          <Box sx={{ display: { xs: 'contents', sm: 'flex' }, width: '100%', height: '250px', margin: "auto" }}>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 1 }}>
                              <ConsensusInfo blockchainData={blockchainData} />
                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 1 }}>
                              <ButtonGroup size="small" color="primary" aria-label="consensus-options" sx={{ marginBottom: 1 }}>
                                <Button onClick={() => handleConsensusViewChange('ApprovalReview')} variant={consensusView === 'ApprovalReview' ? 'contained' : 'outlined'}>Approval & Review</Button>
                                <Button onClick={() => handleConsensusViewChange('InternalProposals')} variant={consensusView === 'InternalProposals' ? 'contained' : 'outlined'}>Internal Proposals</Button>
                              </ButtonGroup>

                              {consensusView === 'InternalProposals' && <CreateConsensusProposal account={blockchainData.walletAddress} />}
                            </Box>
                          </Box>

                          {consensusView === 'ApprovalReview' && <GovernanceApprovalInfos blockchainData={blockchainData} />}
                          {consensusView === 'InternalProposals' && <ConsensusInternalInfo blockchainData={blockchainData} />}
                        </>
                      )}
                    </Box>
                  </>

                ) : (
                  <></>
                )}

              </Box>
            </ConfirmationProvider>
          </HelpProvider>

        </NotificationProvider>
      </SnackbarProvider>

    </ThemeProvider>

  );
}

export default App;
