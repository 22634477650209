import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNotification } from '../../NotificationContext';

const WalletAddress = ({ length, color, walletAddress,  }) => {

  const { triggerNotification } = useNotification();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress);
    triggerNotification(`Address copied to clipboard: ${walletAddress}`, { variant: 'info' });
  };

  const viewAddress = () => {
    window.open(`https://testnet.bscscan.com/address/${walletAddress}`, '_blank');
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography color={color} variant="body2" noWrap sx={{ marginRight: 1, textShadow: "0 0 4px #3737376b" }}>
        {walletAddress.substring(0, length)}...{walletAddress.substring(walletAddress.length - 4)}
      </Typography>
      <IconButton onClick={copyToClipboard} aria-label="copy address" size="small">
        <ContentCopyIcon sx={{color: color}} fontSize="small" />
      </IconButton>
      <IconButton onClick={viewAddress} aria-label="view address" size="small">
        <VisibilityIcon sx={{color: color}} fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default WalletAddress;