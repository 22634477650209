import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import {
  Card,
  CardContent,
  Typography,
  Pagination,
  Grid,
  Box,
  Button
} from '@mui/material';
import ProposalVoteBar from './reusable/ProposalVoteBar';
import VoteApprovalProposal from './VoteApprovalProposal';
import ProposalSkeleton from './reusable/ProposalSkeleton'; // Correct the path as necessary
import StatusChip from './reusable/StatusChip'; // Correct the path as necessary
import { proposalTypesMapping } from '../utils/proposalConfig'; // Correct the path as necessary
import ProposalControls from './reusable/ProposalControls'; // Correct the path as necessary
import ConsensusABI from '../contracts/ConsensusABI';
import { consensusContractAddress } from '../utils/contractConfig';
import { useSendTransaction } from '../requests/ContractRequests';

const GovernanceApprovalInfos = ({ blockchainData }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [isLoading, setIsLoading] = useState(true);
  const [sortOption, setSortOption] = useState('latest'); // Adjust the default value as needed
  const [filter, setFilter] = useState({ reviewed: null, approved: null });
  const [searchId, setSearchId] = useState('');

  const [web3] = useState(new Web3(window.ethereum));
  const sendTransaction = useSendTransaction();


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Function to sort proposals
  const sortProposals = (proposals) => {
    switch (sortOption) {
      case 'latest':
        return [...proposals].sort((a, b) => b.id - a.id);
      case 'oldest':
        return [...proposals].sort((a, b) => a.id - b.id);
      case 'mostVoted':
        return [...proposals].sort((a, b) => (b.voteCountForApproval + b.voteCountAgainst) - (a.voteCountForApproval + a.voteCountAgainst));
      default:
        return proposals;
    }
  };

  const filteredProposals = blockchainData.consensusProposals
    .filter(proposal => filter.reviewed === null || proposal.reviewed.toString() === filter.reviewed)
    .filter(proposal => filter.approved === null || proposal.approved.toString() === filter.approved);

  const handleSearchProposalById = (id) => {
    setSearchId(id);
  };

  // Apply filters, search, and then sorting
  const filteredSortedAndSearchedProposals = sortProposals(
    filteredProposals.filter(proposal =>
      searchId ? proposal.id.toString() === searchId : true
    )
  );

  const indexOfLastProposal = currentPage * itemsPerPage;
  const indexOfFirstProposal = indexOfLastProposal - itemsPerPage;
  const currentProposals = filteredSortedAndSearchedProposals.slice(indexOfFirstProposal, indexOfLastProposal);

  // Update calculateTotalPages to consider filtered and searched proposals
  const calculateTotalPages = () => {
    return Math.ceil(filteredSortedAndSearchedProposals.length / itemsPerPage);
  };

  const minRequired = () => {
    if (blockchainData.membersCount > 0) {
      return (blockchainData.membersCount * 0.8).toFixed();
    } else {
      return 1;
    }
  };

  useEffect(() => {
    if (currentProposals.length > 0) {
      setIsLoading(false);
    }
  }, [currentProposals]);

  const filterOptions = [
    {
      filterType: 'reviewed',
      filterValue: filter.reviewed === null ? 'all' : filter.reviewed,
      onChange: (e) => setFilter({ ...filter, reviewed: e.target.value === 'all' ? null : e.target.value }),
      menuItems: [
        { value: 'all', label: 'All (Reviewed & Not Reviewed)' },
        { value: 'true', label: 'Reviewed Only' },
        { value: 'false', label: 'Not Reviewed Only' }
      ]
    },
    {
      filterType: 'approved',
      filterValue: filter.approved === null ? 'all' : filter.approved,
      onChange: (e) => setFilter({ ...filter, approved: e.target.value === 'all' ? null : e.target.value }),
      menuItems: [
        { value: 'all', label: 'All (Approved & Not Approved)' },
        { value: 'true', label: 'Approved Only' },
        { value: 'false', label: 'Not Approved Only' }
      ]
    }
  ];

  const calculateRemainingVotes = (proposal) => {
    const totalMembers = Number(blockchainData.memberCount);
    if (isNaN(totalMembers) || totalMembers <= 0) {
      console.error('Invalid member count');
      return 0; // Or handle this scenario as needed
    }

    const votesCast = Number(proposal.voteCount);
    if (isNaN(votesCast)) {
      console.error('Invalid votes cast');
      return 0; // Or handle this scenario as needed
    }

    // Determine the approval threshold based on the total number of members
    let approvalThreshold;
    if (totalMembers === 2) {
      approvalThreshold = 1; // Special case for exactly two members
    } else {
      approvalThreshold = Math.ceil((totalMembers * 2) / 3); // For approvals, rounding up
    }

    // Calculate the remaining votes needed to reach the approval threshold
    const remainingVotes = Math.max(0, approvalThreshold - votesCast); // Ensure it doesn't go negative
    return remainingVotes;
  };



  const executeGovernanceProposal = async (proposalId) => {
    const consensusContract = new web3.eth.Contract(ConsensusABI, consensusContractAddress);
    const method = consensusContract.methods.executeGovernanceProposal;
    const args = [proposalId]; // Assuming executeGovernanceProposal takes proposalId as an argument
    const from = blockchainData.walletAddress; // Assuming you have the user's wallet address in blockchainData

    try {
      await sendTransaction(method, args, from);
      // Add any post-transaction success logic here, such as refreshing the proposal list
    } catch (error) {
      console.error(`Error executing proposal ${proposalId}:`, error);
      // Handle errors appropriately
    }
  };

  const isConsensusMember = blockchainData.members.some(member =>
    Web3.utils.toChecksumAddress(member) === Web3.utils.toChecksumAddress(blockchainData.walletAddress)
  );


  // Adjust the return statement
  return (
    <Box sx={{}}>

      <ProposalControls
        proposalCount={blockchainData.proposalCount}
        currentPage={currentPage}
        totalPages={calculateTotalPages()}
        onPaginationChange={handlePageChange}
        sortOption={sortOption}
        onSortChange={(e) => setSortOption(e.target.value)}
        filterOptions={filterOptions}
        onSearchProposalById={handleSearchProposalById} // Pass the search handler here
      />

      {isLoading ? (
        <>
          {[...Array(1)].map((_, i) => (
            <ProposalSkeleton key={i} />
          ))}
        </>
      ) : (
        currentProposals.map((proposal, index) => {
          const proposalTypeName = proposalTypesMapping.find(type => type.id === proposal.proposalType)?.name || "Unknown Type";
          const remainingVotes = calculateRemainingVotes(proposal);

          // Check if the proposal is reviewed and approved, and has no votes for or against
          const isSpecialCase = proposal.reviewed && proposal.approved && proposal.voteCountForApproval === 0 && proposal.voteCountAgainst === 0;


          return (
            <Card key={index} sx={{ marginBottom: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                  <Typography variant="h6" color="primary">Proposal ID: {proposal.id}</Typography>
                  <Typography variant="body2" color="primary">{proposalTypeName}</Typography>
                  <Box>
                    <StatusChip label="Reviewed" isActive={proposal.reviewed} />
                    <StatusChip label="Approved" isActive={proposal.approved} />
                  </Box>
                </Box>
                {!isSpecialCase && (
                  <ProposalVoteBar
                    votesFor={Number(proposal.voteCountForApproval)}
                    votesAgainst={Number(proposal.voteCountAgainst)}
                    proposalType={proposal.proposalType}
                    remainingVotes={remainingVotes}
                    useAgainst={true}
                    useFinalButton={false}
                  />
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2">Request to <br /><b>{proposal.data}</b></Typography>
                    <Typography variant="subtitle2"><b>{proposal.voteCountForReview}</b> Review Votes</Typography>
                  </Grid>
                  {isConsensusMember && (
                    <Grid item xs={12} md={6}>
                      {proposal.approved && proposal.reviewed ? (
                        <>
                          <Typography variant="subtitle2">
                            <b>Proposal approved</b>. No additional votes required
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => executeGovernanceProposal(proposal.id)}
                          >
                            Execute Proposal
                          </Button>
                        </>
                      ) : (
                        <VoteApprovalProposal
                          account={blockchainData.walletAddress}
                          proposalId={proposal.id}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          );
        })
      )}
    </Box>
  );
};

export default GovernanceApprovalInfos;
