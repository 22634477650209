import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import ConsensusABI from '../contracts/ConsensusABI';
import { consensusContractAddress } from '../utils/contractConfig';
import { useSendTransaction } from '../requests/ContractRequests';
import { Select, Grid, MenuItem, Radio, Button, Box, Typography, Paper } from '@mui/material';

 
const VoteApprovalProposal = ({ account, proposalId }) => {
  const [web3] = useState(new Web3(window.ethereum));
  const [proposalType, setProposalType] = useState('REVIEW_GOVERNANCE'); // Set the default value here
  const [approve, setApprove] = useState(false);

  const sendTransaction = useSendTransaction();
  
  const consensusContract = new web3.eth.Contract(ConsensusABI, consensusContractAddress);

  const voteOnConsensusProposal = async () => {
    try {
      let method;
      let args;

      switch (proposalType) {
        case 'REVIEW_GOVERNANCE':
          method = consensusContract.methods.reviewGovernanceProposal;
          args = [proposalId];
          break;
        case 'APPROVE_GOVERNANCE':
          method = consensusContract.methods.approveGovernanceProposal;
          args = [proposalId, approve];
          break;
        default:
          throw new Error('Invalid proposal type');
      }

      // Use ContractRequests to send the transaction
      await sendTransaction(method, args, account);
    } catch (error) {
      // Error handling if needed, though most is handled within sendTransaction
      console.error('Error in voteOnConsensusProposal:', error);
    }
  };

  return (
    <Paper
      sx={{
        p: 0.5,
        maxWidth: '100%',
        margin: 'auto',
        mt: 0.5,
        bgcolor: '#42a5f514',
        border: '0px solid',
        borderColor: 'primary.light',
        boxShadow: 1,
        borderRadius: 2,
        padding: 1
      }}
    >
  {/*     <Typography variant="h9" gutterBottom sx={{ lineHeight: '1.2' }}> 
        Vote on Proposal {proposalId}
      </Typography> */}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}> 
        <Select
          value={proposalType}
          onChange={(e) => setProposalType(e.target.value)}
          size="small"
          /* sx={{
            bgcolor: 'white',
          }} */
        >
          <MenuItem value="REVIEW_GOVERNANCE">Review</MenuItem>
          <MenuItem value="APPROVE_GOVERNANCE">Approve</MenuItem>
        </Select>

        {proposalType === 'APPROVE_GOVERNANCE' && (
          <Box sx={{ display: 'content', alignItems: 'center', mt: 1 }} margin="dense"
          >
            <label>
              <input
                type="radio"
                name="approveDecision"
                value="true"
                checked={approve === true}
                onChange={() => setApprove(true)}
              />
              Approve
            </label>
            <label>
              <input
                type="radio"
                name="approveDecision"
                value="false"
                checked={approve === false}
                onChange={() => setApprove(false)}
              />
              Reject
            </label>
          </Box>
        )}

        <Button
          variant="contained"
          size="small"
          onClick={voteOnConsensusProposal}
          sx={{ mt: 1, width: '100%', height: "40px", margin: "auto" }}
          margin="dense"

        >
          Vote on Proposal {proposalId}
        </Button>
      </Box>
    </Paper>
  );
};

export default VoteApprovalProposal;
