import web3 from '../utils/web3';
import {
  tokenContractAddress,
  consensusContractAddress,
  liquidityContractAddress,
  governanceContractAddress,
  rewardContractAddress,
} from '../utils/contractConfig';

import TokenContractABI from '../contracts/TokenABI';
import consensusContractABI from '../contracts/ConsensusABI';
import liquidityContractABI from '../contracts/LiquidityABI';
import governanceContractABI from '../contracts/GovernanceABI';
import rewardContractABI from '../contracts/RewardABI';

// Creating contract instances
const tokenContract = new web3.eth.Contract(TokenContractABI, tokenContractAddress);
const consensusContract = new web3.eth.Contract(consensusContractABI, consensusContractAddress);
const liquidityContract = new web3.eth.Contract(liquidityContractABI, liquidityContractAddress);
const governanceContract = new web3.eth.Contract(governanceContractABI, governanceContractAddress);
const rewardContract = new web3.eth.Contract(rewardContractABI, rewardContractAddress);

const contracts = {
    token: tokenContract,
    consensus: consensusContract,
    liquidity: liquidityContract,
    governance: governanceContract,
    reward: rewardContract,
  };
  
const ContractInteraction = {
    callContractMethod: async (contractType, methodName, ...args) => {
      try {
        const contract = contracts[contractType];
        if (!contract) {
          throw new Error(`Contract type '${contractType}' not found`);
        }
  
        const method = contract.methods[methodName];
        if (!method) {
          throw new Error(`Method '${methodName}' not found in '${contractType}' contract`);
        }
  
        return await method(...args).call();
      } catch (error) {
        console.error(`Error in callContractMethod: ${contractType}.${methodName}`, error);
        throw error;
      }
    }
};
  
export default ContractInteraction;
