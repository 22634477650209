import React from 'react';
import { Chip, Box } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const StatusChip = ({ label, isActive, isAutoApproved = false }) => (
  <Chip
    label={
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isAutoApproved && label === 'Approved' && (
          <AutorenewIcon sx={{ fontSize: 16, marginLeft: 0.5 }} />
        )}
        {label}
      </Box>
    }
    size="small"
    variant="outlined"
    sx={{
      color: isActive ? 'green' : 'grey',
      borderColor: isActive ? 'green' : 'lightgrey',
      marginRight: '5px',
      backgroundColor: 'transparent'
    }}
  />
);

export default StatusChip;
