// CreateProposal.js
import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import TokenABI from '../contracts/TokenABI';
import BN from 'bn.js';
import GovernanceABI from '../contracts/GovernanceABI';
import { tokenContractAddress, governanceContractAddress } from '../utils/contractConfig';
import { useSendTransaction, useSetAllowance } from '../requests/ContractRequests';
import ContractInteraction from '../requests/ContractInteraction'; // Import ContractInteraction
import { Container, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const CreateProposal = ({ account, proposalCostPercent, tokenDecimals, totalSupply }) => {

  const [web3, setWeb3] = useState(null);
  const [proposalType, setProposalType] = useState('');
  const [proposalParams, setProposalParams] = useState({});
  const [consensusMemberProposalType, setConsensusMemberProposalType] = useState("add");
  const [isAllowanceEnough, setIsAllowanceEnough] = useState(false);

  const sendTransaction = useSendTransaction();
  const setAllowance = useSetAllowance();

  // Calculate the actual token amount required for the proposal
  const getActualProposalCost = () => {
    const proposalCostPercentage = parseFloat(proposalCostPercent) / 10000; // Adjust division here
    return totalSupply * proposalCostPercentage; // Calculate the actual cost
  };

  const proposalCost = getActualProposalCost(); // Use actual proposal cost


  // Ensure web3 and tokenContract are initialized
  useEffect(() => {
    if (window.ethereum && account) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    }
  }, [account]);

  const toTokenWei = (amount, tokenDecimals) => {
    return new BN(amount).mul(new BN(10).pow(new BN(tokenDecimals))).toString();
  };

  const fetchAllowance = async () => {
    try {
      const allowanceAmount = await ContractInteraction.callContractMethod('token', 'allowance', account, governanceContractAddress);
      const allowanceAdjusted = new BN(allowanceAmount).div(new BN(10).pow(new BN(tokenDecimals))).toString();

      // Convert both values to floating-point numbers for comparison
      const allowanceNum = parseFloat(allowanceAdjusted);
      const proposalCostNum = parseFloat(proposalCost);


      setIsAllowanceEnough(allowanceNum >= proposalCostNum);
    } catch (error) {
      console.error('Error fetching allowance:', error);
      setIsAllowanceEnough(false);
    }
  };

  useEffect(() => {
    if (account) {
      fetchAllowance();
    }
  }, [account, proposalCost, tokenDecimals]);

  if (!web3) {
    return <div color="textPrimary">Loading Web3...</div>;
  }

  const governanceContract = new web3.eth.Contract(GovernanceABI, governanceContractAddress);
  const tokenContract = new web3.eth.Contract(TokenABI, tokenContractAddress);

  const handleSetAllowance = async () => {
    const amount = proposalCost; // Use proposalCost for setting allowance
    try {
      await setAllowance(tokenContract, amount, governanceContractAddress, account);
      // Re-fetch allowance after successfully setting it
      fetchAllowance();
    } catch (error) {
      console.error('Error setting allowance:', error);
      // Handle any errors, e.g., by showing a notification or updating state
    }
  };

  const createProposal = async () => {
    if (!account || !proposalType) return;

    try {
      let method;
      let args = [];

      switch (proposalType) {
        case 'TYPE_GAME_WHITELIST_PROPOSAL':
          method = governanceContract.methods.createGameWhitelistProposal;
          args.push(proposalParams.gameAddress, proposalParams.gameName); // Update this line
          break;
        case 'TYPE_CONSENSUS_MEMBER_PROPOSAL':
          if (consensusMemberProposalType === 'add') {
            method = governanceContract.methods.createConsensusAddingProposal;
            // No additional arguments needed for adding
          } else {
            method = governanceContract.methods.createConsensusRemovalProposal;
            args.push(proposalParams.memberToRemove);
          }
          break;
        case 'TYPE_EMERGENCY_RESET_PROPOSAL':
          method = governanceContract.methods.createEmergencyResetProposal;
          break;
        case 'TYPE_SETTING_UPDATE_PROPOSAL':
          method = governanceContract.methods.createSettingProposal;
          args.push(proposalParams.setting, proposalParams.proposedValue);
          break;
        case 'TYPE_WITHDRAW_LIQUIDITY_PROPOSAL':
          method = governanceContract.methods.createWithdrawLiquidityProposal;
          args.push(proposalParams.liquidityContract, proposalParams.lpTokenAmount, proposalParams.targetAddress);
          break;
        default:
          throw new Error('Invalid proposal type');
      }

      await sendTransaction(method, args, account);
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };

  const handleProposalTypeChange = (e) => {
    setProposalType(e.target.value);
    setProposalParams({});
    // Reset consensusMemberProposalType to default when changing proposal type
    if (e.target.value !== 'TYPE_CONSENSUS_MEMBER_PROPOSAL') {
      setConsensusMemberProposalType('add');
    }
  };

  const handleConsensusMemberProposalTypeChange = (e) => {
    setConsensusMemberProposalType(e.target.value);
    setProposalParams({}); // Clear previous inputs
  };

  const handleInputChange = (e) => {
    setProposalParams(prevParams => ({
      ...prevParams,
      [e.target.name]: e.target.value
    }));
  };

  const settingsMapping = [
    { id: 0, name: "Liquidity Fee (0)" },
    { id: 1, name: "Burn Fee (1)" },
    { id: 2, name: "Swap Limit (2)" },
    { id: 3, name: "Transfer Limit (3)" },
    { id: 4, name: "Voting Power Cap (4)" },
    { id: 5, name: "Proposal Cost (5)" },
    { id: 6, name: "Community Tax (6)" },
    { id: 7, name: "Developer Tax (7)" },
    { id: 8, name: "Reward Threshold (8)" },
    { id: 9, name: "Cooldown Min Block (9)" },
    { id: 10, name: "Liquidity Threshold (10)" },
    { id: 11, name: "Voting Period (11)" }
  ];

  // Render helper for input fields
  const renderInputField = (label, name, type = 'text') => (
    <TextField
      label={label}
      type={type}
      name={name}
      value={proposalParams[name] || ''}
      onChange={handleInputChange}
      fullWidth
      variant="outlined"
      margin="dense"  // Set to 'dense' to reduce vertical space
      size="small"   // Set size to small
    />
  );

  // Define a common style for the Box container
  const boxStyle = {
    display: 'flex',
    justifyContent: 'space-around', // Or 'center' based on your design
    alignItems: 'center',
    flexWrap: 'wrap',
    mt: 0,
    width: '100%', // Adjust width as needed
    // Add any other common styles here
  };

  // Define a common style for input fields or divs wrapping them
  const inputFieldStyle = {
    margin: '3px', // Reduced margin
    width: '100%', // Adjust width for side-by-side layout
    marginTop: '10px',
    // Add any other common styles here
  };

  // Define a common style for input fields or divs wrapping them
  const inputFieldStyle2 = {
    margin: '3px', // Reduced margin
    width: '100%', // Adjust width for side-by-side layout
    maxHeight: '38px'
    // Add any other common styles here
  };

  // Define a compact style for FormControl
  const formControlStyle = {
    marginTop: '15px',
    marginBottom: '10px',
    marginLeft: '3px',
    marginRight: '3px',
    width: '100%', // Adjust width to match input fields
    maxHeight: '20px', // Set a specific height
  };

  return (
    <Container maxWidth="lg"> {/* Changed maxWidth to 'lg' for more space */}
      <Box sx={{ marginTop: "-10px" }} display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center" width="79%">
          <FormControl fullWidth margin="normal">
            <InputLabel sx={{ marginTop: "-6px" }} id="proposal-type-label">Select Proposal Type</InputLabel>
            <Select
              sx={{ maxHeight: "38px" }}
              labelId="proposal-type-label"
              value={proposalType}
              onChange={handleProposalTypeChange}
              label="Select Proposal Type"
            >
              <MenuItem value="">Select Proposal Type</MenuItem>
              <MenuItem value="TYPE_GAME_WHITELIST_PROPOSAL">Game Whitelist Proposal</MenuItem>
              <MenuItem value="TYPE_CONSENSUS_MEMBER_PROPOSAL">Consensus Member Proposal</MenuItem>
              <MenuItem value="TYPE_EMERGENCY_RESET_PROPOSAL">Emergency Reset Proposal</MenuItem>
              <MenuItem value="TYPE_SETTING_UPDATE_PROPOSAL">Setting Update Proposal</MenuItem>
              <MenuItem value="TYPE_WITHDRAW_LIQUIDITY_PROPOSAL">Withdraw Liquidity Proposal</MenuItem>
            </Select>
          </FormControl>

        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" width="80%" sx={{ mt: -1 }}> {/* Reduced top margin */}

          {/* Conditional rendering based on proposalType */}
          {
            proposalType === 'TYPE_GAME_WHITELIST_PROPOSAL' && (
              <Box sx={boxStyle}>
                <div style={inputFieldStyle}>{renderInputField('Game Address', 'gameAddress')}</div>
                <div style={inputFieldStyle}>{renderInputField('Game Name', 'gameName')}</div> {/* Add this line */}
              </Box>
            )
          }

          {proposalType === 'TYPE_CONSENSUS_MEMBER_PROPOSAL' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="consensus-member-proposal-type-label">Add or Remove Member</InputLabel>
                <Select
                  labelId="consensus-member-proposal-type-label"
                  value={consensusMemberProposalType}
                  onChange={handleConsensusMemberProposalTypeChange}
                  label="Add or Remove Member"
                >
                  <MenuItem value="add">Add Member</MenuItem>
                  <MenuItem value="remove">Remove Member</MenuItem>
                </Select>
              </FormControl>
              {consensusMemberProposalType === 'remove' && renderInputField('Member to Remove', 'memberToRemove')}
              {/* Remaining UI elements for proposal creation */}
            </Box>
          )}

          {proposalType === 'TYPE_EMERGENCY_RESET_PROPOSAL' && (
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">

            </Box>
          )}

          {
            proposalType === 'TYPE_SETTING_UPDATE_PROPOSAL' && (
              <Box sx={boxStyle}>
                <FormControl fullWidth sx={formControlStyle}>
                  <InputLabel id="setting-label" sx={{ marginTop: "-5px" }}>Setting</InputLabel>
                  <Select
                    labelId="setting-label"
                    name="setting"
                    value={proposalParams.setting !== undefined && proposalParams.setting !== null ? proposalParams.setting : ''}
                    onChange={handleInputChange}
                    label="Setting"
                    sx={{ maxHeight: "38px", lineHeight: "38px" }}
                  >
                    <MenuItem value="">Select Setting</MenuItem>
                    {settingsMapping.map((setting) => (
                      <MenuItem key={setting.id} value={setting.id}>
                        {setting.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={inputFieldStyle}>
                  {renderInputField('Proposed Value', 'proposedValue', 'number')}
                </div>
              </Box>
            )
          }

          {
            proposalType === 'TYPE_WITHDRAW_LIQUIDITY_PROPOSAL' && (
              <Box sx={boxStyle}>
                <div style={inputFieldStyle2}>
                  {renderInputField('Liquidity Contract', 'liquidityContract')}
                </div>
                <div style={inputFieldStyle2}>
                  {renderInputField('LP Token Amount', 'lpTokenAmount', 'number')}
                </div>
                <div style={inputFieldStyle2}>
                  {renderInputField('Target Address', 'targetAddress')}
                </div>
              </Box>
            )
          }
        </Box>

        <Box display="flex" justifyContent="space-around" alignItems="center" width="80%" sx={{ marginTop: "10px" }}>
          {isAllowanceEnough ? (
            <Button
              variant="contained"
              color="primary"
              onClick={createProposal}
              size="medium"
              style={{ width: '100%', height: '40px' }}>
              Create for {Number(proposalCost)} tokens
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={handleSetAllowance}
              size="medium"
              sx={{
                width: '100%',
                height: '40px',
                color: 'primary',
                fontSize: "10px",
                borderColor: 'primary',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 255, 0.04)',
                  borderColor: 'primary',
                },
              }}>
              Set Allowance ({Number(proposalCost.toFixed())} tokens needed)
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default CreateProposal;
