import React, { createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const triggerNotification = (message, options = {}) => {
    enqueueSnackbar(message, options);
  };

  return (
    <NotificationContext.Provider value={{ triggerNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
