import React from 'react';
import { Box, Typography, Divider, useTheme } from '@mui/material';
import WalletAddress from './reusable/WalletAddress';

const ConsensusInfo = ({ blockchainData }) => {
  const theme = useTheme(); // Use the theme to determine the current mode (day/night)

  // Background colors based on theme mode
  const backgroundColor = theme.palette.mode === 'dark' ? '#0d47a1' : '#bbdefb'; // Dark/Grey blue for night and light blue for day

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        padding: '16px',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
        maxWidth: '100%',
      }}
    >
      <Typography variant="h6" color="textPrimary" gutterBottom sx={{ marginLeft: '8px' }}>
        Members ({blockchainData.memberCount})
      </Typography>

      <Divider sx={{ marginBottom: '10px' }} />

      <Box
        sx={{
          position: 'relative',
          maxHeight: '100px',
          overflowY: 'auto',
          paddingRight: '10px'
        }}
      >
        {
          blockchainData.members && blockchainData.members.length > 0 ? (
            blockchainData.members.map((walletAddress, index) => (
              <div key={index} variant="body2" style={{ color: 'white' }}>
                <WalletAddress length={25} color={"white"} walletAddress={walletAddress || "0x00000000000000000000000000000000000000000"} />
              </div>
            ))
          ) : (
            <div variant="body2" style={{ color: 'white' }}>
              No consensus members found.
            </div>
          )
        }

        {/* Fixed Gradient Overlay */}
        <Box sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          height: '40px', // Height of the gradient effect
          background: `linear-gradient(rgba(255, 255, 255, 0), ${backgroundColor})`,
          pointerEvents: 'none', // Allows interaction with elements underneath
        }} />
      </Box>
    </Box>
  );
};

export default ConsensusInfo;
