import React, { createContext, useContext, useState } from 'react';
import ConfirmationDialog from './components/reusable/ConfirmationDialog';

const ConfirmationContext = createContext();

export const useConfirmation = () => useContext(ConfirmationContext);

export const ConfirmationProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [onConfirm, setOnConfirm] = useState(null);

  const requestConfirmation = (details, confirmCallback) => {
    setTransactionDetails(details);
    setOnConfirm(() => () => confirmCallback());
    setOpen(true);
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ConfirmationContext.Provider value={{ requestConfirmation }}>
      {children}
      <ConfirmationDialog
        open={open}
        transactionDetails={transactionDetails}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      />
    </ConfirmationContext.Provider>
  );
};
