import { useState, useEffect } from 'react';
import ContractInteraction from '../requests/ContractInteraction';
import { getWeb3, getBalance } from '../utils/blockchainUtils';

export const useBlockchainData = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [ethBalance, setEthBalance] = useState('');
  const [userTokenBalance, setTokenBalance] = useState('');
  const [totalSupply, setTotalSupply] = useState(0);
  const [decimals, setDecimalsValue] = useState('');
  const [pausedStatusInfo, setPausedStatus] = useState('');
  const [memberCount, setMemberCount] = useState(0);
  const [members, setMembers] = useState(["0x00000000000000000000000000000000000000000"]);
  const [proposalCount, setProposalCount] = useState(0);
  const [proposals, setProposals] = useState([]);
  const [settings, setSettings] = useState([]);
  const [totalRewardBalance, setRewardBalance] = useState(0);
  const [reservedRewardBalance, setRewardReserved] = useState(0);
  const [consensusProposals, setConsensusProposals] = useState([]);
  const [internalConsensusProposals, setInternalConsensusProposals] = useState([]);
  const [whitelistedGames, setWhitelistedGames] = useState([]);


  function convertToFullUnit(value, decimals) {
    const fullValue = value.toString();
    const convertedValue = (Number(fullValue) / Math.pow(10, Number(decimals))).toString();
    return convertedValue;
  }

  const decodeProposalData = (proposalType, encodedData) => {

    const web3 = getWeb3('readOnly'); // Use getWeb3 utility to initialize Web3

    // Check for empty data
    if (!encodedData || encodedData === "") {
      return "No Data";
    }

    const governanceSettings = [
      'LiquidityFee', 'BurnFee', 'SwapLimit', 'TransferLimit', 'VotingPowerCap',
      'ProposalCost', 'CommunityTax', 'DeveloperTax', 'RewardThreshold',
      'CooldownMinBlock', 'LiquidityThreshold', 'VotingPeriod'
    ];

    try {
      switch (proposalType) {
        case 1: // TYPE_GAME_WHITELIST_PROPOSAL
          // Only 'address' is encoded
          const decodedGameAddress = web3.eth.abi.decodeParameter('address', encodedData);
          return `Game Address: ${decodedGameAddress}`;

        case 2: // TYPE_CONSENSUS_MEMBER_PROPOSAL
          const decodedConsensusMember = web3.eth.abi.decodeParameters(['address', 'bool'], encodedData);
          return `Member Address: ${decodedConsensusMember[0]}, Is Addition: ${decodedConsensusMember[1]}`;

        case 3: // TYPE_EMERGENCY_RESET_PROPOSAL
          return "Emergency Reset Proposal";

        case 4: // TYPE_SETTING_UPDATE_PROPOSAL
          const decoded = web3.eth.abi.decodeParameters(['uint256', 'uint256'], encodedData);
          const settingIndex = parseInt(decoded[0], 10);
          const settingName = governanceSettings[settingIndex] || "Unknown";
          return `Setting: ${settingName}, Proposed Value: ${decoded[1]}`;

        case 6: // TYPE_WITHDRAW_LIQUIDITY_PROPOSAL
          const decodedWithdrawLiquidity = web3.eth.abi.decodeParameters(['address', 'uint256', 'address'], encodedData);
          return `Liquidity Contract: ${decodedWithdrawLiquidity[0]}, LP Token Amount: ${decodedWithdrawLiquidity[1]}, Target Address: ${decodedWithdrawLiquidity[2]}`;

        default:
          return "Unknown Proposal Type";
      }

    } catch (error) {
      console.log("error decoding:", error);
      return "Error in decoding data"
    }

  };

  const decodeInternalProposalData = (encodedData) => {
    const web3 = getWeb3('readOnly');

    // Define function signatures
    const functionSignatures = {
      pause: web3.eth.abi.encodeFunctionSignature('pause()'),
      unpause: web3.eth.abi.encodeFunctionSignature('unpause()'),
      removeFromWhitelist: web3.eth.abi.encodeFunctionSignature('removeFromGameWhitelist(address)')
    };

    if (encodedData === functionSignatures.pause) {
      return 'Pause Proposal';
    } else if (encodedData === functionSignatures.unpause) {
      return 'Unpause Proposal';
    } else if (encodedData.startsWith(functionSignatures.removeFromWhitelist.slice(0, 10))) {
      // Extract the address part
      const addressPadded = encodedData.slice(10); // Remove the function signature
      const address = '0x' + addressPadded; // Skip the padding (24 bytes, 48 characters)
      return `Remove From Whitelist Proposal for Address: ${address}`;
    } else {
      return 'Unknown Proposal Type';
    }
  };

  const connectWallet = async () => {
    const provider = window.ethereum;

    if (!provider) {
      alert('Please install an Ethereum wallet like MetaMask to continue.');
      return;
    }

    try {
      // First, check if the wallet is connected to the BSC testnet
      const chainId = await provider.request({ method: 'eth_chainId' });
      // BSC testnet chain ID is 0x61 (97 in decimal)
      if (chainId !== '0x61') {
        alert('Please switch to the BSC Testnet to continue.');
        return;
      }

      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      if (accounts.length === 0) {
        alert('No accounts found. Please ensure your wallet is unlocked and connected.');
      } else {
        setWalletAddress(accounts[0]);
        await updateBalance(accounts[0]);
      }
    } catch (error) {
      console.error(error);
      // Enhanced error handling
      if (error.code) {
        switch (error.code) {
          case 4001: // User rejected the request
            alert('Permission denied: Access to Ethereum accounts was not granted.');
            break;
          case -32602: // Invalid parameters
            alert('Failed to request accounts due to invalid parameters.');
            break;
          case -32603: // Internal JSON-RPC error
            alert('An internal error occurred on the Ethereum provider.');
            break;
          case 4100: // Unauthorized
            alert('Unauthorized: Please authorize this website to access your Ethereum account.');
            break;
          case 4200: // Unsupported method
            alert('Unsupported method: The Ethereum provider does not support the requested method.');
            break;
          case 4900: // Disconnected
            alert('Provider disconnected: Please check your Ethereum connection.');
            break;
          case 4901: // Chain disconnected
            alert('Chain disconnected: Please connect to an available Ethereum chain.');
            break;
          default:
            alert(`An unexpected error occurred: ${error.message}`);
        }
      } else {
        // Handle errors not strictly tied to the Ethereum provider's response
        alert(`An error occurred: ${error.message || 'Please check your wallet and connection.'}`);
      }
    }
  };




  const updateBalance = async (address) => {
    const balance = await getBalance(address);
    setEthBalance(balance);
  };

  useEffect(() => {
    connectWallet();
  }, []);

  const fetchConsensusProposals = async () => {
    // PROPOSALS GOVERNANCE APPROVAL BY CONSENSUS
    try {
      const consensusProposalCount = await ContractInteraction.callContractMethod('consensus', 'governanceProposalCount');
      const proposalCountNum = parseInt(consensusProposalCount, 10);
      const proposalsTemp = [];

      for (let i = 0; i < proposalCountNum; i++) {
        const proposalRaw = await ContractInteraction.callContractMethod('consensus', 'governanceProposals', i);

        const decodedData = decodeProposalData(Number(proposalRaw.proposalType), proposalRaw.data);

        const proposal = {
          proposalType: parseInt(proposalRaw.proposalType, 10),
          data: decodedData || {}, // Use decodedData, handle null or empty case
          voteCountForApproval: parseInt(proposalRaw.voteCountForApproval, 10),
          voteCountAgainst: parseInt(proposalRaw.voteCountAgainst, 10),
          reviewed: proposalRaw.reviewed,
          approved: proposalRaw.approved,
          voteCountForReview: parseInt(proposalRaw.voteCountForReview, 10),
          id: i
        };

        proposalsTemp.push(proposal);
      }
      setConsensusProposals(proposalsTemp);
    } catch (error) {
      console.log("Error fetching governance approval by consensus proposals");
    }

    // PROPOSALS INTERNAL TO CONSENSUS
    try {
      const consensusInternalProposalCount = await ContractInteraction.callContractMethod('consensus', 'proposalCount');
      const internalProposalCountNum = parseInt(consensusInternalProposalCount, 10);
      const internalProposalsTemp = [];

      for (let i = 0; i < internalProposalCountNum; i++) {
        const internalProposalRaw = await ContractInteraction.callContractMethod('consensus', 'proposals', i);

        const internalDecodedData = decodeInternalProposalData(internalProposalRaw[0]); // Assuming first element is proposal type

        const internalProposal = {
          data: internalDecodedData, // Assuming this is the proposal data
          executed: internalProposalRaw[1], // Assuming this is the executed status
          voteCount: parseInt(internalProposalRaw[2], 10), // Assuming this is the vote count
          id: i // The proposal ID
        };

        internalProposalsTemp.push(internalProposal);
      }
      setInternalConsensusProposals(internalProposalsTemp);
    } catch (error) {
      console.log("Error fetching internal consensus proposals");
    }
  };

  const fetchProposals = async (proposalCount, decimals) => {
    const proposalsTemp = [];
    for (let i = 0; i < proposalCount; i++) {
      const proposal = await ContractInteraction.callContractMethod('governance', 'proposals', i);
      const decodedData = decodeProposalData(Number(proposal.proposalType), proposal.data);

      proposalsTemp.push({
        id: i,
        proposer: proposal.proposer, // Assuming proposal.proposer exists
        proposalType: proposal.proposalType, // Assuming proposal.proposalType exists
        data: decodedData,
        votesFor: (Number(proposal.votesFor) / Math.pow(10, Number(decimals))).toString(),
        votesAgainst: (Number(proposal.votesAgainst) / Math.pow(10, Number(decimals))).toString(),
        executed: proposal.executed, // Assuming proposal.executed exists
        reviewed: proposal.reviewed, // Assuming proposal.reviewed exists
        approved: proposal.approved, // Assuming proposal.approved exists
        autoApproved: proposal.autoApproved // Assuming proposal.autoApproved exists
      });
    }
    setProposals(proposalsTemp);
  };

  const fetchSettings = async () => {
    const settingsTemp = [];
    for (let i = 0; i < 12; i++) {
      const setting = await ContractInteraction.callContractMethod('token', 'getSettingValue', i);
      settingsTemp.push({
        id: i,
        value: setting.toString()
      });
    }
    return settingsTemp;
  };

  const fetchData = async () => {
    if (!walletAddress) return;

    try {
      const [
        pausedStatus,
        decimalsValue,
        tokenBalance,
        totalSupplyFull,
        memberCountValue,
        founder,
        getAllConsensusMembersPromise, // This is still a Promise at this point
        proposalCountValue,
        rewardBalance,
        rewardReserved
      ] = await Promise.all([
        ContractInteraction.callContractMethod('token', 'paused'),
        ContractInteraction.callContractMethod('token', 'decimals'),
        ContractInteraction.callContractMethod('token', 'balanceOf', walletAddress),
        ContractInteraction.callContractMethod('token', 'totalSupply'),
        ContractInteraction.callContractMethod('consensus', 'consensusMemberCount'),
        ContractInteraction.callContractMethod('consensus', 'founder'),
        ContractInteraction.callContractMethod('consensus', 'getAllConsensusMembers'),
        ContractInteraction.callContractMethod('governance', 'proposalCount'),
        ContractInteraction.callContractMethod('reward', 'getRewardBalance'),
        ContractInteraction.callContractMethod('reward', 'totalReservedRewards')
      ]);

      const allConsensusMembers = await getAllConsensusMembersPromise; // Properly awaited here
      const allMembers = [founder, ...allConsensusMembers]; // Now spreads correctly

      setPausedStatus(pausedStatus.toString());
      setDecimalsValue(Number(decimalsValue));
      setTokenBalance(convertToFullUnit(tokenBalance, decimalsValue));
      setTotalSupply(convertToFullUnit(totalSupplyFull, decimalsValue));
      setMemberCount(memberCountValue.toString());
      setMembers(allMembers);
      setProposalCount(proposalCountValue.toString());
      setRewardBalance(rewardBalance);
      setRewardReserved(rewardReserved);



      // Fetch whitelisted games data
      const whitelistedGamesData = await ContractInteraction.callContractMethod('token', 'getWhitelistedGames');

      // Check if whitelistedGamesData is not empty and format the data
      const formattedWhitelistedGames = [];

      if (whitelistedGamesData[0].length > 0) {
        for (let i = 0; i < whitelistedGamesData[0].length; i++) {
          const address = whitelistedGamesData[0][i];
          const gameData = whitelistedGamesData[1][i];

          const isActive = gameData.isActive;
          const name = gameData.name;

          formattedWhitelistedGames.push({
            address,
            name,
            isActive,
          });
        }
      }

      setWhitelistedGames(formattedWhitelistedGames);

      const settingsTemp = await fetchSettings();
      setSettings(settingsTemp);

      await fetchProposals(parseInt(proposalCountValue, 10), decimalsValue);

      if (walletAddress) {
        fetchConsensusProposals();
      }

      await updateBalance(walletAddress);

    } catch (error) {
      console.error('Error fetching blockchain data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, [walletAddress]);

  return {
    walletAddress,
    ethBalance,
    totalSupply,
    memberCount,
    members,
    proposalCount,
    proposals,
    settings,
    connectWallet,
    pausedStatusInfo,
    decimals,
    consensusProposals,
    userTokenBalance,
    internalConsensusProposals,
    totalRewardBalance,
    reservedRewardBalance,
    whitelistedGames
  };
};
