import React, { useState } from 'react';
import Web3 from 'web3';
import GovernanceABI from '../contracts/GovernanceABI';
import { governanceContractAddress } from '../utils/contractConfig';
import { useSendTransaction } from '../requests/ContractRequests';
import { Button, Paper, Typography } from '@mui/material';

const UnstackTokens = ({ account, proposalId }) => {
  const [web3] = useState(new Web3(window.ethereum));

  const sendTransaction = useSendTransaction();
  const governanceContract = new web3.eth.Contract(GovernanceABI, governanceContractAddress);

  const unstakeTokens = async () => {
    try {
      // Directly using the voteProposal method as only one proposalType 'INTERNAL' is used
      const method = governanceContract.methods.unstakeTokens;
      const args = [proposalId];

      await sendTransaction(method, args, account);
    } catch (error) {
      console.error('Error in UnstackTokens:', error);
    }
  };

  return (
      <Button
        variant="contained"
        size="small"
        onClick={unstakeTokens}
        sx={{ mt: 1 }}
      >
        Unstake Tokens {proposalId}
      </Button>
  );
};

export default UnstackTokens;
