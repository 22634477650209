import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, handleClose, handleConfirm, transactionDetails }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Transaction</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm your transaction details:
        </DialogContentText>
        <pre>{JSON.stringify(transactionDetails, null, 2)}</pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
