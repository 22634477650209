import React, { useContext } from 'react';
import { Box, Typography, styled, useTheme } from '@mui/material';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import StorageIcon from '@mui/icons-material/Storage'; // For Total Supply
import FireIcon from '@mui/icons-material/Whatshot'; // For Total Burned
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { HelpContext } from '../HelpContext';
import NumberFormatter from './reusable/NumberFormatter';
import formatNumber from './reusable/formatNumber'; // Assuming the function is saved in a file named formatNumber.js


const TokenInfo = ({ blockchainData }) => {
  const { HelpIcon } = useContext(HelpContext);
  const theme = useTheme();

  const segmentColors = {
    light: {
      paused: '#1e88e5',
      supply: '#42a5f5',
      burned: '#90caf9'
    },
    dark: {
      paused: '#0d47a1',
      supply: '#1976d2',
      burned: '#2196f3'
    }
  };

  const getSegmentColor = (segmentType) => {
    return theme.palette.mode === 'dark' ? segmentColors.dark[segmentType] : segmentColors.light[segmentType];
  };

  const InfoBarSegment = styled(({ segmentType, ...otherProps }) => <Box {...otherProps} />)(({ theme, segmentType }) => ({
    height: '60px',
    width: '33.33%',
    backgroundColor: getSegmentColor(segmentType),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    borderRight: segmentType !== 'burned' && `1px solid ${theme.palette.divider}`,
    margin: 0,
  }));

  const settingsMapping = [
    { id: 0, name: "Liquidity Fee", helpId: "liquidityFeeInfo" },
    { id: 1, name: "Burn Fee", helpId: "burnFeeInfo" },
    { id: 2, name: "Swap Limit", helpId: "swapLimitInfo" },
    { id: 3, name: "Transfer Limit", helpId: "transferLimitInfo" },
    { id: 4, name: "Voting Power Cap", helpId: "votingPowerCapInfo" },
    { id: 5, name: "Proposal Cost", helpId: "proposalCostInfo" },
    { id: 6, name: "Community Tax", helpId: "communityTaxInfo" },
    { id: 7, name: "Developer Tax", helpId: "developerTaxInfo" },
    { id: 8, name: "Reward Threshold", helpId: "rewardThresholdInfo" },
    { id: 9, name: "Cooldown Min Block", helpId: "cooldownMinBlockInfo" },
    { id: 10, name: "Liquidity Threshold", helpId: "liquidityThresholdInfo" },
    { id: 11, name: "Voting Period", helpId: "votingPeriodInfo" }
  ];


  const getSettingNameById = (id) => {
    const setting = settingsMapping.find(setting => setting.id === id);
    return setting ? setting.name : "Unknown Setting";
  };

  const totalValue = blockchainData.settings.reduce((acc, setting) => acc + setting.value, 0);

  const getSegmentWidth = (value, total) => {
    const minWidth = 10;
    const width = (value / total) * 100;
    return Math.max(width, minWidth);
  };

  const SettingSegment = styled(Box)(({ theme, value, total }) => ({
    height: '60px',
    minWidth: `${getSegmentWidth(value, total)}%`,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5px',
    color: 'white',
    boxSizing: 'border-box'
  }));


  const IconWithMargin = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: '4px'
  });

  const InfoBar = styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: 0
  });

  const TitleAndInfoBar = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 0,
    gap: '10px'
  });

  const pausedStatusText = blockchainData.pausedStatusInfo === "true" ? "Paused" : "Unpaused";

  const availableRewardRaw = Number(blockchainData.totalRewardBalance) - Number(blockchainData.reservedRewardBalance);
  const availableReward = availableRewardRaw / (10 ** Number(blockchainData.decimals));

  return (
    <Box borderRadius={"10px"} overflow={"hidden"} maxWidth={"100%"}>
      <TitleAndInfoBar>
        <InfoBar>
          <InfoBarSegment segmentType="paused" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ position: 'absolute', top: 1, left: 1 }}>
              <EmojiEventsIcon />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <HelpIcon helpId="rewardBalanceInfo" />
            </div>
            <Typography variant="body2" sx={{ textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '13px' }, width: '100%', marginBottom: "10px", marginTop: "17px" }}>
              Reward
            </Typography>
            <Typography variant="h6" sx={{ marginTop: -1.5, fontSize: { xs: '12px', sm: '16px', md: '19px' }, fontWeight: "bold" }}>
              <NumberFormatter number={availableReward.toFixed()} /> Tokens
            </Typography>
          </InfoBarSegment>

          <InfoBarSegment segmentType="paused" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ position: 'absolute', top: 1, left: 1 }}>
              <PauseCircleOutlineIcon />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <HelpIcon helpId="pausedStatusInfo" />
            </div>
            <Typography variant="body2" sx={{ textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '13px' }, width: '100%', marginBottom: "10px", marginTop: "17px" }}>
              Paused Status
            </Typography>
            <Typography variant="h6" sx={{ marginTop: -1.5, fontSize: { xs: '12px', sm: '16px', md: '19px' }, fontWeight: "bold" }}>
              {pausedStatusText}
            </Typography>
          </InfoBarSegment>

          <InfoBarSegment segmentType="supply" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ position: 'absolute', top: 1, left: 1 }}>
              <StorageIcon />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <HelpIcon helpId="totalSupplyInfo" />
            </div>
            <Typography variant="body2" sx={{ textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '13px' }, width: '100%', marginBottom: "10px", marginTop: "17px" }}>
              Total Supply
            </Typography>
            <Typography variant="h6" sx={{ marginTop: -1.5, fontSize: { xs: '12px', sm: '16px', md: '19px' }, fontWeight: "bold" }}>
              <NumberFormatter number={blockchainData.totalSupply} /> Tokens
            </Typography>
          </InfoBarSegment>

          <InfoBarSegment segmentType="burned" style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ position: 'absolute', top: 1, left: 1 }}>
              <FireIcon />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <HelpIcon helpId="totalBurnedInfo" />
            </div>
            <Typography variant="body2" sx={{ textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '13px' }, width: '100%', marginBottom: "10px", marginTop: "17px" }}>
              Total Burned
            </Typography>
            <Typography variant="h6" sx={{ marginTop: -1.5, fontSize: { xs: '12px', sm: '16px', md: '19px' }, fontWeight: "bold" }}>
              <NumberFormatter number={3000000000 - blockchainData.totalSupply} /> Tokens
            </Typography>
          </InfoBarSegment>

        </InfoBar>
      </TitleAndInfoBar>

      <Box display="flex" flexWrap="wrap" width="100%" overflow="auto">
        {blockchainData.settings.map((setting, index) => {
          let displayValue = '';
          let helpId = settingsMapping.find(s => s.id === setting.id)?.helpId || 'defaultHelpId';

          switch (setting.id) {
            case 0: // Liquidity Fee
            case 1: // Burn Fee
            case 3: // Transfer Limit
            case 4: // Voting Power Cap
            case 5: // Proposal Cost
            case 6: // Community Tax
            case 7: // Developer Tax
              displayValue = `${setting.value / 100}%`; // Convert basis points to percentage
              break;
            case 2: // Swap Limit
              displayValue = `${formatNumber(setting.value)}`; // Convert basis points to percentage
              break;
            case 8: // Reward Threshold
            case 10: // Liquidity Threshold
              displayValue = `${formatNumber(setting.value)} Tokens`; // Display as tokens with thousands separator
              break;
            case 9: // Cooldown Min Block
              displayValue = `${setting.value} Blocks`; // Display as blocks
              break;
            case 10: // Liquidity Threshold
              displayValue = `${formatNumber(setting.value)} Tokens`; // Display as blocks
              break;
            case 11: // Voting Period Min Block
              displayValue = `${setting.value} Blocks`; // Display as blocks
              break;
            default:
              displayValue = setting.value; // Default raw value
          }
          return (
            <SettingSegment key={setting.id} sx={{ position: 'relative', textAlign: "center", flexBasis: "25%" }} value={setting.value} total={totalValue}>
              <div style={{ position: 'absolute', top: 0, right: 0, padding: '4px' }}>
                <HelpIcon helpId={helpId} />
              </div>
              <Typography variant="caption" noWrap sx={{
                fontSize: { xs: '9px', sm: '10px', md: '13px' }, // Adjust sizes as needed
                width: '100%',
                paddingTop: { xs: '20px', sm: '0', md: '0' }
              }}>
                {getSettingNameById(setting.id)}
              </Typography>
              <Typography variant="h6" noWrap sx={{
                fontSize: { xs: '14px', sm: '16px', md: '22px' }, // Adjust sizes as needed
              }}>
                <b>{displayValue}</b>
              </Typography>
            </SettingSegment>
          );
        })}
      </Box>
    </Box>
  );
};

export default TokenInfo;
