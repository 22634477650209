import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSendTransaction } from '../../requests/ContractRequests';
import { consensusContractAddress } from '../../utils/contractConfig';
import ConsensusABI from '../../contracts/ConsensusABI';
import Web3 from 'web3';


const ProposalVoteBar = ({
  votesFor,
  votesAgainst,
  proposalType,
  remainingVotes,
  useAgainst,
  proposalId,
  account,
  useFinalButton = true
}) => {
  const [web3] = useState(new Web3(window.ethereum));


  const sendTransaction = useSendTransaction();

  const consensusContract = new web3.eth.Contract(ConsensusABI, consensusContractAddress);

  // Additional logic for checking enough support
  const hasEnoughSupport = () => {
    const totalVotes = votesFor + votesAgainst;
    switch (proposalType) {
      case 3:
        return votesFor >= (totalVotes * 80) / 100; // 80% of total votes need to be in favor
      case 6:
        return votesFor >= (totalVotes * 80) / 100; // Same threshold for this example
      default:
        return votesFor > votesAgainst; // Simple majority for other proposals
    }
  };

  const executeProposal = async () => {
    try {
      const method = consensusContract.methods.executeGovernanceProposal;
      const args = [proposalId];

      await sendTransaction(method, args, account);
    } catch (error) {
      console.error('Error executing on proposal:', error);
      // Error handling already done in ContractRequests
    }
  };

  const finalizeProposal = async () => {
    try {
      const method = consensusContract.methods.finalizeGovernanceProposal;
      const args = [proposalId];

      await sendTransaction(method, args, account);
    } catch (error) {
      console.error('Error finalizing on proposal:', error);
      // Error handling already done in ContractRequests
    }
  };

  const calculateBarWidth = () => {
    const totalVotes = useAgainst ? votesFor + votesAgainst + remainingVotes : votesFor + remainingVotes;

    return {
      forWidth: totalVotes ? (votesFor / totalVotes) * 100 : 0,
      againstWidth: useAgainst && totalVotes ? (votesAgainst / totalVotes) * 100 : 0,
      remainingWidth: totalVotes ? (remainingVotes / totalVotes) * 100 : 0,
    };
  };

  const { forWidth, againstWidth, remainingWidth } = calculateBarWidth();

  const isMinimumReached = remainingVotes <= 0;
  const sufficientSupport = hasEnoughSupport();

  const votesTotal = votesFor + votesAgainst;

  return (
    <>
      <Box sx={{ display: 'flex', height: 15, width: '100%', backgroundColor: 'lightgrey', borderRadius: '15px', overflow: 'hidden', marginBottom: 2 }}>
        <Box sx={{ width: `${forWidth}%`, backgroundColor: '#1976d2' }} />
        {useAgainst && <Box sx={{ width: `${againstWidth}%`, backgroundColor: '#D80032' }} />}
        <Box sx={{ width: `${remainingWidth}%`, backgroundColor: '#BDBDBD' }} />
      </Box>
      {isMinimumReached ? (
        <>
          <Typography variant="body2" color="green">
            Minimum votes reached. Proposal is now executable.
          </Typography>
          <Button
            size="xs"
            onClick={sufficientSupport ? executeProposal : finalizeProposal}
            sx={{ flex: 1, visibility: useFinalButton ? "visible" : "hidden" }}
          >
            Execute Proposal
          </Button>
        </>
      ) : (
        <Typography variant="body2" color="orange">
          {`${remainingVotes} votes needed before end. All votes: ${votesTotal}`}
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Typography variant="body2" color="#1976d2">Votes For: <b>{votesFor}</b></Typography>
        {useAgainst && <Typography variant="body2" color="#D80032">Votes Against: <b>{votesAgainst}</b></Typography>}
      </Box>
    </>
  );
};

export default ProposalVoteBar;
