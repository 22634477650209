import React, { useState, useEffect } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, Button, Box, Typography, Paper } from '@mui/material';
import Web3 from 'web3';
import BN from 'bn.js';
import GovernanceABI from '../contracts/GovernanceABI';
import TokenABI from '../contracts/TokenABI';
import { tokenContractAddress, governanceContractAddress } from '../utils/contractConfig';
import { useSendTransaction, useSetAllowance } from '../requests/ContractRequests';
import ContractInteraction from '../requests/ContractInteraction';

const VoteOnProposal = ({ account, decimals, proposalId: initialProposalId, userTokenBalance }) => {
  const [web3] = useState(new Web3(window.ethereum));
  const [proposalId, setProposalId] = useState(initialProposalId !== undefined ? initialProposalId : '');
  const [voteFor, setVoteFor] = useState(false);
  const [stakeAmount, setStakeAmount] = useState('');
  const [allowance, setAllowanceValue] = useState(null);

  const sendTransaction = useSendTransaction();
  const setAllowance = useSetAllowance();

  const governanceContract = new web3.eth.Contract(GovernanceABI, governanceContractAddress);
  const tokenContract = new web3.eth.Contract(TokenABI, tokenContractAddress);

  const toTokenWei = (amount, tokenDecimals) => {
    // Convert the amount to BN, then multiply by 10^decimals
    return new BN(amount).mul(new BN(10).pow(new BN(tokenDecimals))).toString();
  };
  

  const voteOnProposal = async () => {
    try {
      const stakeAmountInTokenWei = toTokenWei(stakeAmount, decimals);
      const method = governanceContract.methods.voteOnProposal;
      const args = [proposalId, voteFor, stakeAmountInTokenWei];

      await sendTransaction(method, args, account);
    } catch (error) {
      console.error('Error voting on proposal:', error);
      // Error handling already done in ContractRequests
    }
  };
  
  const handleSetAllowance = async () => {
    try {
      await setAllowance(tokenContract, stakeAmount, governanceContractAddress, account);
      await fetchAllowance(); // Re-fetch allowance to update the state
    } catch (error) {
      console.error('Error setting allowance:', error);
    }
  };

  useEffect(() => {
    if (initialProposalId !== undefined) {
      setProposalId(initialProposalId);
    }
  }, [initialProposalId]);

  const fetchAllowance = async () => {
    try {
      const allowanceAmount = await ContractInteraction.callContractMethod('token', 'allowance', account, governanceContractAddress);
      setAllowanceValue(new BN(allowanceAmount));
    } catch (error) {
      console.error('Error fetching allowance:', error);
    }
  };

  useEffect(() => {
    if (account) {
      fetchAllowance();
    }
  }, [account]);


   // Convert stakeAmount and userTokenBalance to float for comparison
  const stakeAmountFloat = parseFloat(stakeAmount);
  const userBalanceFloat = parseFloat(userTokenBalance);
  const isBalanceSufficient = userBalanceFloat >= stakeAmountFloat;

  const getActionButton = () => {
    // Check if stakeAmount is empty
    if (!stakeAmount) {
      return { label: 'Enter amount', variant: 'outlined', disabled: true };
    }

    if (!isBalanceSufficient) {
      return { label: 'Insufficient Balance', variant: 'outlined', disabled: true };
    }
    // Convert stakeAmount to Wei for allowance comparison
    const stakeAmountWei = toTokenWei(stakeAmount, decimals);
    const isAllowanceSufficient = allowance && allowance.gte(new BN(stakeAmountWei));

    return isAllowanceSufficient
      ? { label: 'Vote', variant: 'contained', disabled: false }
      : { label: 'Set Allowance', variant: 'outlined', disabled: false };
  };

  const actionButton = getActionButton();


  return (
    <Paper
      sx={{
        p: 0.5, // Reduced padding
        maxWidth: '100%',
        margin: 'auto',
        mt: 0.5, // Reduced margin-top
        bgcolor: '#42a5f514',
        border: '0px solid',
        borderColor: 'primary.light',
        boxShadow: 1,
        borderRadius: 2,
        padding: 1
      }}
    >
      <Typography variant="h9" gutterBottom sx={{ lineHeight: '1.2' }}> 
        {/* Adjusted line height */}
        Vote on Proposal {proposalId}
      </Typography>
  
      <TextField
        fullWidth
        type="number"
        
        value={stakeAmount}
        onChange={(e) => setStakeAmount(e.target.value)}
        placeholder="Stake Amount"
        size="small"
        variant="outlined"
        margin="dense"
      />
  
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}> 
        {/* Reduced gap and margin-top */}
        <RadioGroup row name="vote" value={voteFor ? "for" : "against"} onChange={(e) => setVoteFor(e.target.value === "for")}>
          <FormControlLabel value="for" control={<Radio size="small" />} label="For" />
          <FormControlLabel value="against" control={<Radio size="small" />} label="Against" />
        </RadioGroup>
  
        <Button
          variant={actionButton.variant}
          size="xs"
          onClick={actionButton.label === 'Vote' ? voteOnProposal : handleSetAllowance}
          disabled={actionButton.disabled}
          sx={{ flex: 1 }}
        >
          {actionButton.label}
        </Button>
      </Box>
    </Paper>
  );
};


export default VoteOnProposal;
