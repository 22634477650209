import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Pagination,
  Grid,
  Box,
  Select,
  MenuItem
} from '@mui/material';
import VoteOnProposal from './VoteOnProposal';
import ProposalVoteBar from './reusable/ProposalVoteBar';
import ProposalSkeleton from './reusable/ProposalSkeleton'; // Correct the path as necessary
import StatusChip from './reusable/StatusChip'; // Correct the path as necessary
import { proposalTypesMapping } from '../utils/proposalConfig'; // Correct the path as necessary
import ProposalControls from './reusable/ProposalControls'; // Correct the path as necessary
import UnstackTokens from './UnstackTokens';

const GovernanceInfo = ({ blockchainData }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [sortOption, setSortOption] = useState('latest');
  const [filter, setFilter] = useState({ executed: null, approved: null });
  const [isLoading, setIsLoading] = useState(true);
  const [searchId, setSearchId] = useState('');


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const sortProposals = (proposals) => {
    switch (sortOption) {
      case 'latest':
        return [...proposals].sort((a, b) => b.id - a.id);
      case 'oldest':
        return [...proposals].sort((a, b) => a.id - b.id);
      case 'mostVoted':
        return [...proposals].sort((a, b) => (b.votesFor + b.votesAgainst) - (a.votesFor + a.votesAgainst));
      default:
        return proposals;
    }
  };

  const filterProposals = (proposals) => {
    return proposals.filter(proposal => {
      const filterExecuted = filter.executed === null || proposal.executed === filter.executed;
      const filterApproved = filter.approved === null || proposal.approved === filter.approved;
      return filterExecuted && filterApproved;
    });
  };

  const sortedAndFilteredProposals = sortProposals(filterProposals(blockchainData.proposals));

  useEffect(() => {
    const totalPages = Math.ceil(sortedAndFilteredProposals.length / itemsPerPage);
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1);
    }
  }, [sortedAndFilteredProposals.length, currentPage]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleSearchProposalById = (id) => {
    setSearchId(id);
  };

  const currentItems = searchId
    ? sortedAndFilteredProposals.filter(proposal => proposal.id.toString() === searchId)
    : sortedAndFilteredProposals.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (currentItems.length > 0) {
      setIsLoading(false);
    }
  }, [currentItems]);

  const filterOptions = [
    {
      filterType: 'executed',
      filterValue: filter.executed === null ? 'all' : filter.executed.toString(),
      onChange: (e) => setFilter({ ...filter, executed: e.target.value === 'all' ? null : e.target.value === 'true' }),
      menuItems: [
        { value: 'all', label: 'All (Executed & Not Executed)' },
        { value: 'true', label: 'Executed Only' },
        { value: 'false', label: 'Not Executed Only' }
      ]
    },
    {
      filterType: 'approved',
      filterValue: filter.approved === null ? 'all' : filter.approved.toString(),
      onChange: (e) => setFilter({ ...filter, approved: e.target.value === 'all' ? null : e.target.value === 'true' }),
      menuItems: [
        { value: 'all', label: 'All (Approved & Not Approved)' },
        { value: 'true', label: 'Approved Only' },
        { value: 'false', label: 'Not Approved Only' }
      ]
    }
  ];

  // Function to calculate minimum votes (can be customized per component)
  const TYPE_WITHDRAW_LIQUIDITY_PROPOSAL = 6;
  const TYPE_EMERGENCY_RESET_PROPOSAL = 3; // Assuming 3 is the type for Emergency Reset Proposal
  const decimals = Number(blockchainData.decimals);

  return (
    <Box sx={{ margin: 'auto' }}>
      <ProposalControls
        proposalCount={blockchainData.proposalCount}
        currentPage={currentPage}
        totalPages={Math.ceil(sortedAndFilteredProposals.length / itemsPerPage)}
        onPaginationChange={handlePageChange}
        sortOption={sortOption}
        onSortChange={(e) => setSortOption(e.target.value)}
        filterOptions={filterOptions}
        onSearchProposalById={handleSearchProposalById}
      />

      {isLoading ? (
        <>
          {[...Array(1)].map((_, i) => (
            <ProposalSkeleton key={i} />
          ))}
        </>
      ) : (
        currentItems.map((proposal, index) => {
          const totalSupply = Number(blockchainData.totalSupply);
          const totalVotes = Number(proposal.votesFor) + Number(proposal.votesAgainst);
          const proposalType = proposalTypesMapping.find(type => type.id === Number(proposal.proposalType));
          const proposalTypeName = proposalType ? proposalType.name : "Unknown Type";
          let minVotesNeeded = 0; // Initialize with 0 to handle cases where additional votes are not required
        
          switch (Number(proposal.proposalType)) {
            case 3: // TYPE_EMERGENCY_RESET_PROPOSAL
            minVotesNeeded = ((totalSupply * 40) / 100) - totalVotes;
              break;
            case 6: // TYPE_WITHDRAW_LIQUIDITY_PROPOSAL
                // If total votes are less than 50% of totalSupply, calculate votes needed to reach 50%
                minVotesNeeded = (totalSupply / 2) - totalVotes;
              break;
            default:
              // For other types, calculate votes needed to reach half of total supply
              if (totalVotes < totalSupply / 2) {
                minVotesNeeded = (totalSupply / 2) - totalVotes;
              }
          }
        
          // Ensure minVotesNeeded is not negative, in case calculations exceed expected values
          minVotesNeeded = Math.max(0, minVotesNeeded);

          const isReviewedAndApproved = proposal.reviewed && proposal.approved;
          const isExecuted = proposal.executed;
          
          return (
            <Card key={index} sx={{ marginBottom: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                  <Typography variant="h6" color="primary">
                    Proposal {proposal.id}
                  </Typography>
                  <Typography variant="h8" color="primary">{proposalTypeName}</Typography>
                  <Box>
                    <StatusChip label="Reviewed" isActive={proposal.reviewed} />
                    <StatusChip label="Approved" isActive={proposal.approved} isAutoApproved={proposal.autoApproved} />
                    <StatusChip label="Executed" isActive={proposal.executed} />
                  </Box>
                </Box>
                <ProposalVoteBar
                  votesFor={Number(proposal.votesFor)}
                  votesAgainst={Number(proposal.votesAgainst)}
                  proposalType={Number(proposal.proposalType)}
                  remainingVotes={minVotesNeeded}
                  useAgainst={true}
                  proposalId={proposal.id}
                  account={blockchainData.walletAddress}
                  specialConditionMessage={typeof minVotesNeeded === "string" ? minVotesNeeded : undefined}
                />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2">Request to <br /><b style={{fontSize: "12px"}}>{proposal.data}</b></Typography>
                    <br />
                    <Typography variant="subtitle2">Created by <br /><b style={{fontSize: "12px"}}>{proposal.proposer}</b></Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {isReviewedAndApproved && !isExecuted ? (
                      // If the proposal is reviewed and approved but not executed, show the voting options
                      <VoteOnProposal
                        account={blockchainData.walletAddress}
                        decimals={blockchainData.decimals}
                        proposalId={proposal.id}
                        userTokenBalance={blockchainData.userTokenBalance}
                      />
                    ) : !isReviewedAndApproved ? (
                      // If the proposal has not been reviewed and approved, display a message
                      <Typography variant="subtitle2">
                        Proposal needs to be reviewed and approved by consensus first.
                      </Typography>
                    ) : isExecuted ? (
                      // If the proposal is executed, show a confirmation message and the UnstackTokens button
                      <>
                        <Typography variant="subtitle2">
                          <b>Proposal executed.</b> No additional votes required.
                        </Typography>
                        <UnstackTokens
                          account={blockchainData.walletAddress}
                          proposalId={proposal.id}
                        />
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })
      )}

    </Box>
  );
};

export default GovernanceInfo;
