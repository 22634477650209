import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid
} from '@mui/material';
import StatusChip from './reusable/StatusChip';
import ProposalVoteBar from './reusable/ProposalVoteBar';
import ProposalSkeleton from './reusable/ProposalSkeleton';
import ProposalControls from './reusable/ProposalControls';
import VoteOnConsensusProposal from './VoteOnConsensusProposal';

const ConsensusInternalInfo = ({ blockchainData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({ executed: null });
    const [sortOption, setSortOption] = useState('latest');
    const [searchId, setSearchId] = useState('');

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const sortProposals = (proposals) => {
        switch (sortOption) {
            case 'latest':
                return [...proposals].sort((a, b) => b.id - a.id);
            case 'oldest':
                return [...proposals].sort((a, b) => a.id - b.id);
            case 'mostVoted':
                return [...proposals].sort((a, b) => b.voteCount - a.voteCount);
            default:
                return proposals;
        }
    };

    const filteredProposals = blockchainData.internalConsensusProposals
        .filter(proposal => filter.executed === null || proposal.executed.toString() === filter.executed)
        .filter(proposal => searchId ? proposal.id.toString() === searchId : true);

    const filteredSortedProposals = sortProposals(filteredProposals);

    const indexOfLastProposal = currentPage * itemsPerPage;
    const indexOfFirstProposal = indexOfLastProposal - itemsPerPage;
    const currentProposals = filteredSortedProposals.slice(indexOfFirstProposal, indexOfLastProposal);

    const calculateTotalPages = () => {
        return Math.ceil(filteredSortedProposals.length / itemsPerPage);
    };

    useEffect(() => {
        if (currentProposals.length > 0) {
            setIsLoading(false);
        }
    }, [currentProposals]);

    const filterOptions = [
        {
            filterType: 'executed',
            filterValue: filter.executed === null ? 'all' : filter.executed,
            onChange: (e) => setFilter({ ...filter, executed: e.target.value === 'all' ? null : e.target.value }),
            menuItems: [
                { value: 'all', label: 'All' },
                { value: 'true', label: 'Executed Only' },
                { value: 'false', label: 'Not Executed Only' }
            ]
        },
        // Add other filters here if necessary
    ];

    const calculateRemainingVotes = (proposal) => {
        const totalMembers = Number(blockchainData.memberCount);
        if (isNaN(totalMembers) || totalMembers <= 0) {
            console.error('Invalid member count');
            return 0; // Or handle this scenario as needed
        }
    
        const votesCast = Number(proposal.voteCount);
        if (isNaN(votesCast)) {
            console.error('Invalid votes cast');
            return 0; // Or handle this scenario as needed
        }
    
        // Determine the approval threshold based on the total number of members
        let approvalThreshold;
        if (totalMembers === 2) {
            approvalThreshold = 1; // Special case for exactly two members
        } else {
            approvalThreshold = Math.ceil((totalMembers * 2) / 3); // For approvals, rounding up
        }
    
        // Calculate the remaining votes needed to reach the approval threshold
        const remainingVotes = Math.max(0, approvalThreshold - votesCast); // Ensure it doesn't go negative
        return remainingVotes;
    };



    return (
        <Box sx={{ /* margin: '20px' */ }}> {/* Example maxWidth setting */}

            <ProposalControls
                proposalCount={filteredSortedProposals.length}
                currentPage={currentPage}
                totalPages={calculateTotalPages()}
                onPaginationChange={handlePageChange}
                sortOption={sortOption}
                onSortChange={(e) => setSortOption(e.target.value)}
                filterOptions={filterOptions}
                searchId={searchId}
                onSearchProposalById={setSearchId}
            />

            {isLoading ? (
                [...Array(itemsPerPage)].map((_, i) => <ProposalSkeleton key={i} />)
            ) : (
                currentProposals.map((proposal, index) => {
                    const remainingVotes = calculateRemainingVotes(proposal);

                    return (
                        <Card key={index} sx={{ marginBottom: 3 }}> {/* Removed padding and width from here */}
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" color="primary">Proposal ID: {proposal.id}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <StatusChip label="Executed" isActive={proposal.executed} />
                                            <Typography variant="subtitle1" sx={{ marginLeft: 2 }}>Vote Count: {proposal.voteCount}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            <ProposalVoteBar
                                                votesFor={proposal.voteCount}
                                                votesAgainst={0} // Since your data doesn't have votes against
                                                proposalType={"Internal Proposal"}
                                                remainingVotes={remainingVotes}
                                                useAgainst={false}
                                                useFinalButton={false}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginLeft: "1px" }}>

                                        <Grid item xs={12} md={6}>
                                            <Typography variant="body2"><b>{proposal.data}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} sx={{ textAlign: "right"}}>
                                            {proposal.executed ? (
                                                <>
                                                    <Typography variant="subtitle2">
                                                        <b>Proposal executed</b>. No additional votes required
                                                    </Typography>
                                                </>
                                            ) :
                                                (
                                                    <VoteOnConsensusProposal
                                                        account={blockchainData.walletAddress}
                                                        proposalId={proposal.id}
                                                    />
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })
            )}
        </Box>
    );
};

export default ConsensusInternalInfo;
