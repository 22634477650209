import React from 'react';
import { Box, Typography, Divider, useTheme } from '@mui/material';
import WalletAddress from './reusable/WalletAddress';

const GamesInfo = ({ blockchainData }) => {
  const theme = useTheme(); // Use the theme to determine the current mode (day/night)

  // Background colors based on theme mode
  const backgroundColor = theme.palette.mode === 'dark' ? '#0d47a1' : '#bbdefb'; // Dark/Grey blue for night and light blue for day

  const whitelistedGames = blockchainData.whitelistedGames || [];


  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        padding: '16px',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
        maxWidth: '100%',
      }}
    >
      <Typography variant="h6" color="textPrimary" gutterBottom sx={{ marginLeft: '8px' }}>
        Whitelisted Games ({whitelistedGames.length})
      </Typography>

      <Divider sx={{ marginBottom: '10px' }} />

      <Box
        sx={{
          position: 'relative',
          maxHeight: '200px',
          overflowY: 'auto',
          paddingRight: '10px'
        }}
      >
        {whitelistedGames.length > 0 ? (
          whitelistedGames.map((game, index) => (
            <Box key={index} sx={{ marginBottom: '10px' }}>
              <Typography variant="body1" color="textPrimary" sx={{ fontWeight: 'bold' }}>
                {game.name}
              </Typography>
              <WalletAddress length={35} color="white" walletAddress={game.address} />
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
            No whitelisted games found.
          </Typography>
        )}

        {/* Gradient Overlay */}
        <Box sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          height: '40px', // Height of the gradient effect
          background: `linear-gradient(rgba(255, 255, 255, 0), ${backgroundColor})`,
          pointerEvents: 'none', // Allows interaction with elements underneath
        }} />
      </Box>
    </Box>
  );
};

export default GamesInfo;
