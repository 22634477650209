import React from 'react';
import { Card, CardContent, Box, Skeleton, Grid } from '@mui/material';

const ProposalSkeleton = () => (
  <Card sx={{ marginBottom: 3 }}>
    <CardContent>
      {/* Skeleton for Title and Type */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Skeleton variant="text" width="20%" />
        <Skeleton variant="text" width="15%" />
      </Box>

      {/* Skeleton for Progress Bar */}
      <Skeleton variant="rectangular" height={15} />

      {/* Skeleton for Votes */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, marginBottom: 2 }}>
        <Skeleton variant="text" width="30%" />
        <Skeleton variant="text" width="30%" />
      </Box>

      {/* Skeleton for Request, Created by, and Vote Button */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="80%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="rectangular" height={36} width="60%" />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default ProposalSkeleton;
