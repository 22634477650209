// CreateConsensusProposal.js
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import ConsensusABI from '../contracts/ConsensusABI';
import { consensusContractAddress } from '../utils/contractConfig';
import { useSendTransaction, useSetAllowance } from '../requests/ContractRequests';
import { Container, Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';


const CreateConsensusProposal = ({ account }) => {
  const [web3, setWeb3] = useState(null);
  const [proposalType, setProposalType] = useState('');
  const [proposalParams, setProposalParams] = useState({});

  const sendTransaction = useSendTransaction();

  useEffect(() => {
    if (window.ethereum && account) {
      setWeb3(new Web3(window.ethereum));
    }
  }, [account]);

  if (!web3) {
    return <div>Loading Web3...</div>;
  }

  const consensusContract = new web3.eth.Contract(ConsensusABI, consensusContractAddress);

  const createProposal = async () => {
    if (!account || !proposalType) return;

    try {
      let method;
      let args = [];

      switch (proposalType) {
        case 'PAUSE_PROPOSAL':
          method = consensusContract.methods.createPauseProposal;
          break;
        case 'UNPAUSE_PROPOSAL':
          method = consensusContract.methods.createUnpauseProposal;
          break;
        case 'GAME_REMOVE_PROPOSAL':
          method = consensusContract.methods.createGameRemoveProposal;
          args.push(proposalParams.gameContractAddress);
          break;
        default:
          throw new Error('Invalid proposal type');
      }

      // Use ContractRequests to send the transaction
      await sendTransaction(method, args, account);
    } catch (error) {
      console.error('Error creating consensus proposal:', error);
    }
  };

  const handleProposalTypeChange = (e) => {
    setProposalType(e.target.value);
    setProposalParams({});
  };

  const handleInputChange = (e) => {
    setProposalParams(prevParams => ({
      ...prevParams,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <Container maxWidth="100%">
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        
        <Grid item xs={12} style={{ maxWidth: '80%', margin: 'auto', paddingTop: "20px" }}>
          <FormControl fullWidth size="small">
            <InputLabel id="proposal-type-label">Select Proposal Type</InputLabel>
            <Select
              labelId="proposal-type-label"
              value={proposalType}
              onChange={handleProposalTypeChange}
              label="Select Proposal Type"
            >
              <MenuItem value="">Select Proposal Type</MenuItem>
              <MenuItem value="PAUSE_PROPOSAL">Pause Proposal</MenuItem>
              <MenuItem value="UNPAUSE_PROPOSAL">Unpause Proposal</MenuItem>
              <MenuItem value="GAME_REMOVE_PROPOSAL">Game Remove Proposal</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {proposalType === 'GAME_REMOVE_PROPOSAL' && (
          <Grid item xs={12} style={{ maxWidth: '80%', margin: 'auto' }}>
            <TextField
              label="Game Contract Address"
              type="text"
              name="gameContractAddress"
              value={proposalParams.gameContractAddress || ''}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
        )}
        
        <Grid item xs={12} style={{ maxWidth: '80%', margin: 'auto' }}>
          <Button variant="contained" color="primary" onClick={createProposal} fullWidth>
            Create Proposal
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateConsensusProposal;