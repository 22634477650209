import React, { useState } from 'react';
import Web3 from 'web3';
import ConsensusABI from '../contracts/ConsensusABI';
import { consensusContractAddress } from '../utils/contractConfig';
import { useSendTransaction } from '../requests/ContractRequests';
import { Button, Paper, Typography } from '@mui/material';

const VoteOnConsensusProposal = ({ account, proposalId }) => {
  const [web3] = useState(new Web3(window.ethereum));

  const sendTransaction = useSendTransaction();
  const consensusContract = new web3.eth.Contract(ConsensusABI, consensusContractAddress);

  const voteOnConsensusProposal = async () => {
    try {
      // Directly using the voteProposal method as only one proposalType 'INTERNAL' is used
      const method = consensusContract.methods.voteProposal;
      const args = [proposalId];

      await sendTransaction(method, args, account);
    } catch (error) {
      console.error('Error in voteOnConsensusProposal:', error);
    }
  };

  return (
      <Button
        variant="contained"
        size="small"
        onClick={voteOnConsensusProposal}
        sx={{ mt: 1 }}
      >
        Vote on Proposal {proposalId}
      </Button>
  );
};

export default VoteOnConsensusProposal;
