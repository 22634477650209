import Web3 from 'web3';
import { useNotification } from '../NotificationContext';
import { useConfirmation } from '../ConfirmationContext'; // Import the context

// Helper function to extract error message
const extractErrorMessage = (error) => {
  if (error && error.message) {
    const revertFound = error.message.match(/revert (.*)/);
    if (revertFound && revertFound[1]) {
      return revertFound[1];
    }
    if (error.message.includes('Internal JSON-RPC error.')) {
      if (error.data && error.data.message) {
        return error.data.message;
      } else if (typeof error.data === 'string') {
        return error.data;
      }
    }
    return error.message;
  }
  return 'An unknown error occurred.';
};

// Custom hook for sending transactions
export const useSendTransaction = () => {
  const { triggerNotification } = useNotification();
  const { requestConfirmation } = useConfirmation();

  const sendTransaction = async (method, args, from) => {
    // Request confirmation
    return new Promise((resolve, reject) => {
      requestConfirmation(
        { method, args, from }, // Transaction details
        async () => {
          try {
            // Proceed with the transaction after confirmation
            const web3 = new Web3(window.ethereum); // Assuming window.ethereum is available
            const estimatedGas = await method(...args).estimateGas({ from });
            const gasPrice = await web3.eth.getGasPrice(); // Fetch current gas price
            const transaction = await method(...args).send({ from, gas: estimatedGas, gasPrice });
            triggerNotification(`Transaction successful! Transaction hash: ${transaction.transactionHash}`, { variant: 'success' });
            resolve(transaction);
          } catch (error) {
            console.error('Error during transaction:', error);
            triggerNotification(`Transaction failed: ${extractErrorMessage(error)}`, { variant: 'error' });
            reject(error);
          }
        }
      );
    });
  };

  return sendTransaction;
};

// Custom hook for setting allowance
export const useSetAllowance = () => {
  const { triggerNotification } = useNotification();

  const setAllowance = async (tokenContract, amount, spender, from) => {
    try {
      const web3 = new Web3(window.ethereum); // Assuming window.ethereum is available
      const amountInWei = Web3.utils.toWei(amount, 'ether');
      const gasPrice = await web3.eth.getGasPrice(); // Fetch current gas price
      await tokenContract.methods.approve(spender, amountInWei).send({ from, gasPrice });
      triggerNotification('Allowance set successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Error setting allowance:', error);
      triggerNotification(`Failed to set allowance: ${extractErrorMessage(error)}`, { variant: 'error' });
    }
  };

  return setAllowance;
};
